<template>
  <div>
    <div class="text-lg font-bold mb-5">Preferences</div>
    <div v-if="preferencesEmty">
      <div class="text-sm font-bold mb-5 text-center">No Preferences Yet</div>
    </div>
    <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" v-else>
      <div
        v-for="(preference, i) in Object.keys(preferences)"
        :key="i"
        class="col-span-1 p-4"
        :class="
          preferenceLength > 1 &&
          'border-b xl:border-t-0 xl:border-l border-blue-200'
        "
      >
        <div class="opacity-75 text-sm font-medium mb-1">
          {{ preference | fromSlug | sentenceCase }}
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          {{ preferences[preference] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  computed: {
    preferences() {
      return this.staff?.profile?.preferences;
    },
    preferencesEmty() {
      return typeof this.staff?.profile?.preferences === 'undefined';
    },
    preferenceLength() {
      return !this.preferencesEmpty && Object.keys(this.preferences)?.length;
    },
  },
};
</script>
